import { Container, Header } from '@awsui/components-react';
import { FC, useEffect, useState } from 'react';

import ReactMarkdown from 'react-markdown';

const Documentation: FC = () => {
  const [docs, setDocs] = useState('');

  useEffect(() => {
    import(`../../../../markdown/userDocumentation.md`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setDocs(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  });
  return (
    <Container
      header={<Header variant="h2">WorkSpaces Self Service Portal 2.0</Header>}
    >
      <ReactMarkdown children={docs}></ReactMarkdown>
    </Container>
  );
};

export default Documentation;
