import { FC } from 'react';
import {
  Form,
  Button,
  SpaceBetween,
  FormField,
  Flashbar,
  Textarea,
  Container,
  ColumnLayout,
  Header
} from '@awsui/components-react';

import { Formik } from 'formik';
import * as yup from 'yup';
import Moment from 'react-moment';

import { FlashbarProps } from '@awsui/components-react';

import ValueWithLabel from '../../../common/ValueWithLabel';
import {
  ICreateUserReactivationRequest,
  IUserReactivationRequest
} from '../../../../interfaces/domain/userReactivationRequest';

interface ViewProps {
  formValues: ICreateUserReactivationRequest;
  handleSubmittedForm: (data: ICreateUserReactivationRequest) => Promise<void>;
  handleCancellation: (uuid: string) => Promise<void>;
  flashBarItems: FlashbarProps.MessageDefinition[];
  pendingRequests: IUserReactivationRequest[];
  rejectedRequests: IUserReactivationRequest[];
}

const View: FC<ViewProps> = ({
  formValues,
  handleSubmittedForm,
  handleCancellation,
  flashBarItems,
  pendingRequests,
  rejectedRequests
}) => {
  const validationSchema = yup.object({
    justification: yup.string().required()
  });

  const renderSubmittedRequest = (userReactivationRequest: IUserReactivationRequest) => {
    const hasPending = userReactivationRequest.requestStatus === 'Pending';
    const description = (hasPending) ?
      'You already have requested a reactivation. Please allow time for the Admins to approve or reject this request' :
      'Your previous reactivation request was rejected.';

    return (
      <Container
        id="reactivate-request-viewer"
        header={
          <Header
            variant="h2"
            description={description}
            actions={
              hasPending ? 
              <Button
                id="reactivate-request-cancel-button"
                onClick={() => handleCancellation(userReactivationRequest.uuid)}
              >
                Cancel
              </Button> :
              <></>
            }
          >
            Requested
          </Header>
        }
      >
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="UUID">
              {userReactivationRequest.uuid}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Status">
              {userReactivationRequest.requestStatus}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Created At">
              <Moment unix local format="LLLL">
                {userReactivationRequest.createdAt}
              </Moment>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label="Justification">
              {userReactivationRequest.justification}
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    );
  };

  const renderCreateForm = (
    <Formik
      validateOnChange={true}
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        handleSubmittedForm(data).then((resp) => {
          resetForm();
          setSubmitting(false);
        });
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => (
        <SpaceBetween direction="vertical" size="xs">
          <Flashbar items={flashBarItems} />
          <Form
            id="reactivate-request-form"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  id="reactivate-request-form-submit-button"
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <FormField label="Justification" errorText={errors.justification}>
              <Textarea
                id="reactivate-request-form-justification-field"
                value={values.justification}
                onChange={({ detail }) =>
                  setFieldValue('justification', detail.value)
                }
                invalid={!!errors.justification}
              />
            </FormField>
          </Form>
        </SpaceBetween>
      )}
    </Formik>
  );

  const view = () => {
    if (pendingRequests.length > 0) {
      return renderSubmittedRequest(pendingRequests[0]);
    } else {
      return <SpaceBetween direction='vertical' size='xs'>
          {renderCreateForm}
          {rejectedRequests.length > 0 ? renderSubmittedRequest(rejectedRequests[0]) : <></>}
        </SpaceBetween>;
    }
  };

  return view();
};
export default View;
