import Cookies from 'js-cookie';

import { HttpClient } from './httpClient';

import { IApiResponse } from './interfaces';
import {
  ICreateUserWorkSpaceDecommissionRequest,
  IUserWorkSpaceDecommissionRequest
} from '../../interfaces/workSpaces/userWorkSpaceDecommissionRequest';
import {
  ICreateUserWorkSpaceProvisionRequest,
  ICreateUserWorkSpaceRequest,
  IUpdateUserWorkSpaceProvisionRequest,
  IUserWorkSpaceProvisionRequest
} from '../../interfaces/workSpaces/userWorkSpaceProvisionRequest';
import { IUserWorkSpace } from '../../interfaces/workSpaces/userworkSpaces';
import { IBundle, ISimpleBundle, IUpdateBundle } from '../../interfaces/workSpaces/bundle';
import {
  ICreateHomeGroup,
  IHomeGroup,
  IUpdateHomeGroup
} from '../../interfaces/domain/homegroup';
import {
  IUser,
  ICreateUser,
  ICurrentUser,
  IUpdateUser
} from '../../interfaces/domain/user';
import {
  ICreateUserAccountRequestSubmission,
  IUpdateUserAccountRequest,
  IUserAccountRequest
} from '../../interfaces/domain/userAccountRequest';
import { IUserReactivationRequest } from '../../interfaces/domain/userReactivationRequest';
import {
  IDuoType,
  IGetAllDevicesByUserName,
  IUpdateDuoType,
  ICreateDuoType,
  IDuoPlatform,
  ICreateDuoPlatform,
  IUpdateDuoPlatform,
  ICreateDuoPhoneResponse,
  IDuoPhoneActivation,
  IAssociateDuoTokenRequest,
  IDissociateDuoTokenRequest,
  IDeletePhoneRequest,
  ICreatePhoneRequest
} from '../../interfaces/integrations/duo';
import { IPortalConfig, IPortalSettings } from '../../interfaces/portal/settings';
import {
  ICreateSupportTopic,
  ISupportTopic
} from '../../interfaces/portal/supportTopic';
import { IContactSupport } from '../../interfaces/portal/contactSupport';
import { IGroup } from '../../interfaces/domain/group';
import {
  IDirectory,
  ISimpleDirectory,
  IUpdateDirectory
} from '../../interfaces/workSpaces/directory';
import {
  IApprovedDomain,
  ICreateApprovedDomain,
  IUpdateApprovedDomain
} from '../../interfaces/authentication/approvedDomain';

// Fix missing polyfill for Buffer due to new webpack version
window.Buffer = window.Buffer || require('buffer').Buffer;

export class MainServiceApi extends HttpClient {
  public constructor() {
    super(Cookies.get('main-service-api') || 'http://localhost:3001');
  }

  //DOMAIN NAMESPACE - START

  syncActiveDirectory = async () => {
    return await this.post('/domain/sync/active_directory');
  };
  syncWorkSpaces = async () => {
    return await this.post('/domain/sync/workspaces');
  };
  syncWorkSpaceBundles = async () => {
    return await this.post('/domain/sync/workspace_bundles');
  };
  syncWorkSpaceDirectories = async () => {
    return await this.post('/domain/sync/workspace_directories');
  };

  isUserPortalAdmin = async () => {
    return await this.get<{ authorized: boolean }>('/domain/auth/is_admin');
  };

  whoAmI = async () => {
    return await this.get<ICurrentUser>('/domain/auth/who_am_i');
  };

  userAlreadyExists = async () => {
    return await this.get<{ exists: boolean }>('/domain/auth/user_exists');
  };

  getAllHomeGroups = async () => {
    return await this.get<IHomeGroup[]>('/domain/home_groups/all');
  };

  getAllGroups = async () => {
    return await this.get<IGroup[]>('/domain/groups/all');
  };

  getNonHomeGroupGroups = async () => {
    return await this.get<IGroup[]>('/domain/groups/available');
  };

  getHomeGroupByCurrentUser = async () => {
    return await this.get<IHomeGroup>('/domain/home_groups/by_current_user');
  };
  getHomeGroupByUser = async (userName: string) => {
    return await this.get<IHomeGroup>('/domain/home_groups/by_user', {
      userName
    });
  };
  getHomeGroupsByUserDomain = async () => {
    return await this.get<IHomeGroup[]>('/domain/home_groups/by_email_domain');
  };
  createHomeGroup = async (payload: ICreateHomeGroup) => {
    return await this.post('/domain/home_groups/', {
      homeGroup: payload
    });
  };
  updateHomeGroup = async (groupName: string, payload: IUpdateHomeGroup) => {
    return await this.put('/domain/home_groups/', {
      groupName: groupName,
      attributes: payload
    });
  };

  getAllUsers = async () => {
    return await this.get<IUser[]>('/domain/users/all');
  };
  getUserByUserName = async (userName: string) => {
    return await this.get<IUser>('/domain/users/by_user_name', {
      userName: userName
    });
  };
  getPendingUserAccountRequests = async () => {
    return await this.get<IUserAccountRequest[]>(
      '/domain/user_account_requests/pending'
    );
  };
  getPendingUserAccountRequestCount = async () => {
    return await this.get<{ [count: string]: number }>(
      '/domain/user_account_requests/pending/count'
    );
  };
  getAllUserAccountRequests = async () => {
    return await this.get<IUserAccountRequest[]>(
      '/domain/user_account_requests/all'
    );
  };
  getUserAccountRequestsForSelf = async () => {
    return await this.get<IUserAccountRequest[]>(
      '/domain/user_account_requests/self'
    );
  };
  getUserAccountRequestByUuid = async (uuid: string) => {
    return await this.get<IUserAccountRequest>(
      '/domain/user_account_requests/by_uuid',
      { userAccountRequestUuid: uuid }
    );
  };

  createUserAccountRequest = async (payload: ICreateUserAccountRequestSubmission) => {
    return await this.post('/domain/user_account_requests', {
      userAccountRequest: {
        ...payload
      }
    });
  };
  approveUserAccountRequests = async (uuid: string) => {
    return await this.post('/domain/user_account_requests/approve', {
      userAccountRequestUuid: uuid
    });
  };
  rejectUserAccountRequests = async (uuid: string, rejectionReason: string) => {
    return await this.post('/domain/user_account_requests/reject', {
      userAccountRequestUuid: uuid,
      rejectionReason: rejectionReason
    });
  };
  updateUserAccountRequest = async (
    uuid: string,
    attributes: IUpdateUserAccountRequest
  ) => {
    return await this.put('/domain/user_account_requests/', {
      userAccountRequestUuid: uuid,
      attributes
    });
  };

  getPendingUserReactivationRequests = async () => {
    return await this.get<IUserReactivationRequest[]>(
      '/domain/user_reactivation_requests/pending'
    );
  };
  getPendingUserReactivationRequestCount = async () => {
    return await this.get<{ [count: string]: number }>(
      '/domain/user_reactivation_requests/pending/count'
    );
  };
  getAllUserReactivationRequests = async () => {
    return await this.get<IUserReactivationRequest[]>(
      '/domain/user_reactivation_requests/all'
    );
  };
  getUserReactivationRequestsForSelfByStatus = async (status: string) => {
    return await this.get<IUserReactivationRequest[]>(
      '/domain/user_reactivation_requests/self_by_status',
      {
        requestStatus: status
      }
    );
  };
  getUserReactivationRequestByUuid = async (uuid: string) => {
    return await this.get<IUserReactivationRequest>(
      '/domain/user_reactivation_requests/by_uuid',
      {
        userReactivationRequestUuid: uuid
      }
    );
  };
  createUserReactivationRequest = async (justification: string) => {
    return await this.post<IApiResponse>(
      '/domain/user_reactivation_requests',
      {
        justification: justification
      }
    );
  };
  approveUserReactivationRequest = async (uuid: string) => {
    return await this.post<IApiResponse>(
      '/domain/user_reactivation_requests/approve',
      {
        userReactivationRequestUuid: uuid
      }
    );
  };
  rejectUserReactivationRequest = async (uuid: string, rejectionReason: string) => {
    return await this.post<IApiResponse>(
      '/domain/user_reactivation_requests/reject',
      {
        userReactivationRequestUuid: uuid,
        rejectionReason: rejectionReason
      }
    );
  };
  deprecateUserReactivationRequest = async (uuid: string) => {
    return await this.delete<IApiResponse>(
      '/domain/user_reactivation_requests/',
      {
        userReactivationRequestUuid: uuid
      }
    );
  };

  requestPasswordReset = async (payload: {
    userName: string;
    userPassword?: string;
  }) => {
    return await this.post('/domain/users/reset_password', payload);
  };

  canCurrentUserRequestPasswordReset = async () => {
    return await this.get<{ [canUserResetPassword: string]: boolean }>(
      '/domain/users/can_reset_password'
    );
  };
  requestUpdateUserAccountEnabledStatus = async (payload: {
    userName: string;
  }) => {
    return await this.post(
      '/domain/users/update_account_enabled_status',
      payload
    );
  };
  createUser = async (payload: ICreateUser) => {
    return await this.post('/domain/users/', {
      user: payload
    });
  };
  updateUser = async (userName: string, payload: IUpdateUser) => {
    return await this.put('/domain/users/', {
      userName: userName,
      attributes: payload
    });
  };

  //DOMAIN NAMESPACE - END

  //WORKSPACE NAMESPACE - START

  getAllBundles = async () => {
    return await this.get<IBundle[]>('/workspace/bundles/all');
  };

  getSimpleBundlesByHomeGroup = async (homeGroup: string) => {
    return await this.get<ISimpleBundle[]>('/workspace/bundles/simple_by_home_group', {
      homeGroup
    });
  };

  getAllDirectories = async () => {
    return await this.get<IDirectory[]>('/workspace/directories/all');
  };

  getSimpleDirectoriesByHomeGroup = async (homeGroup: string) => {
    return await this.get<ISimpleDirectory[]>('/workspace/directories/simple_by_home_group', {
      homeGroup
    });
  };

  updateBundle = async (bundleId: string, payload: IUpdateBundle) => {
    return await this.put('/workspace/bundles/', {
      bundleId: bundleId,
      attributes: payload
    });
  };
  updateDirectory = async (directoryId: string, payload: IUpdateDirectory) => {
    return await this.put('/workspace/directories/', {
      directoryId: directoryId,
      attributes: payload
    });
  };

  getWorkSpacesByUserName = async (userName: string) => {
    if (userName) {
      return await this.get<IUserWorkSpace[]>('/workspace/users/by_user_name', {
        userName
      });
    } else {
      return await this.get<IUserWorkSpace[]>('/workspace/users/by_user_name');
    }
  };

  requestRebootWorkSpace = async (workSpaceId: string) => {
    return await this.post('/workspace/manage/reboot', {
      workSpaceId: workSpaceId
    });
  };

  requestRebuildWorkSpace = async (workSpaceId: string) => {
    return await this.post('/workspace/manage/rebuild', {
      workSpaceId: workSpaceId
    });
  };

  requestStartWorkSpace = async (workSpaceId: string) => {
    return await this.post('/workspace/manage/start', {
      workSpaceId: workSpaceId
    });
  };

  requestStopWorkSpace = async (workSpaceId: string) => {
    return await this.post('/workspace/manage/stop', {
      workSpaceId: workSpaceId
    });
  };

  // only for admins
  requestDecommissionWorkSpace = async (workSpaceId: string) => {
    return await this.post('/workspace/manage/decommission', {
      workSpaceId: workSpaceId
    });
  };

  // only for admins
  createUserWorkSpace = async (payload: ICreateUserWorkSpaceRequest) => {
    return await this.post('/workspace/manage/create', {
      createUserWorkspaceRequest: payload
    });
  };

  // only for users
  requestWorkSpaceDecommissionRequest = async (
    payload: ICreateUserWorkSpaceDecommissionRequest
  ) => {
    return await this.post('/workspace/decommission_requests', {
      userWorkSpaceDecommissionRequest: payload
    });
  };

  requestWorkSpaceProvisionRequest = async (
    payload: ICreateUserWorkSpaceProvisionRequest
  ) => {
    return await this.post('/workspace/provision_requests', {
      userWorkSpaceProvisionRequest: payload
    });
  };

  getPendingUserWorkSpaceProvisionRequests = async () => {
    return await this.get<IUserWorkSpaceProvisionRequest[]>(
      '/workspace/provision_requests/pending'
    );
  };
  getPendingUserWorkSpaceProvisionRequestCount = async () => {
    return await this.get<{ [count: string]: number }>(
      '/workspace/provision_requests/pending/count'
    );
  };
  getAllUserWorkSpaceProvisionRequests = async () => {
    return await this.get<IUserWorkSpaceProvisionRequest[]>(
      '/workspace/provision_requests/all'
    );
  };

  getUserWorkSpaceProvisionRequestByUuid = async (uuid: string) => {
    return await this.get<IUserWorkSpaceProvisionRequest>(
      '/workspace/provision_requests/by_uuid',
      {
        userWorkSpaceProvisionRequestUuid: uuid
      }
    );
  };

  approveUserWorkSpaceProvisionRequest = async (uuid: string) => {
    return await this.post('/workspace/provision_requests/approve', {
      userWorkSpaceProvisionRequestUuid: uuid
    });
  };
  rejectUserWorkSpaceProvisionRequest = async (
    uuid: string,
    rejectionReason: string
  ) => {
    return await this.post('/workspace/provision_requests/reject', {
      userWorkSpaceProvisionRequestUuid: uuid,
      rejectionReason: rejectionReason
    });
  };
  updateUserWorkSpaceProvisionRequest = async (
    uuid: string,
    payload: IUpdateUserWorkSpaceProvisionRequest
  ) => {
    return await this.put('/workspace/provision_requests/', {
      userWorkSpaceProvisionRequestUuid: uuid,
      attributes: { ...payload }
    });
  };
  getPendingUserWorkSpaceDecommissionRequests = async () => {
    return await this.get<IUserWorkSpaceDecommissionRequest[]>(
      '/workspace/decommission_requests/pending'
    );
  };
  getPendingUserWorkSpaceDecommissionRequestCount = async () => {
    return await this.get<{ [count: string]: number }>(
      '/workspace/decommission_requests/pending/count'
    );
  };
  getAllUserWorkSpaceDecommissionRequests = async () => {
    return await this.get<IUserWorkSpaceDecommissionRequest[]>(
      '/workspace/decommission_requests/all'
    );
  };
  getUserWorkSpaceDecommissionRequestByUuid = async (uuid: string) => {
    return await this.get<IUserWorkSpaceDecommissionRequest>(
      '/workspace/decommission_requests/by_uuid',
      {
        userWorkSpaceDecommissionRequestUuid: uuid
      }
    );
  };
  approveUserWorkSpaceDecommissionRequest = async (uuid: string) => {
    return await this.post('/workspace/decommission_requests/approve', {
      userWorkSpaceDecommissionRequestUuid: uuid
    });
  };
  rejectUserWorkSpaceDecommissionRequest = async (
    uuid: string,
    rejectionReason: string
  ) => {
    return await this.post('/workspace/decommission_requests/reject', {
      userWorkSpaceDecommissionRequestUuid: uuid,
      rejectionReason: rejectionReason
    });
  };

  //WORKSPACE NAMESPACE - END

  //INTEGRATIONS NAMESPACE - START
  getAllDuoTypes = async () => {
    return await this.get<IDuoType[]>('/integrations/duo/phones/types/all');
  };
  createDuoType = async (payload: ICreateDuoType) => {
    return await this.post('/integrations/duo/phones/types/', {
      duoType: payload
    });
  };
  updateDuoType = async (uuid: string, payload: IUpdateDuoType) => {
    return await this.put('/integrations/duo/phones/types/', {
      uuid: uuid,
      attributes: payload
    });
  };

  getAllDuoPlatforms = async () => {
    return await this.get<IDuoPlatform[]>(
      '/integrations/duo/phones/platforms/all'
    );
  };
  createDuoPlatform = async (payload: ICreateDuoPlatform) => {
    return await this.post('/integrations/duo/phones/platforms/', {
      duoPlatform: payload
    });
  };
  updateDuoPlatform = async (uuid: string, payload: IUpdateDuoPlatform) => {
    return await this.put('/integrations/duo/phones/platforms/', {
      uuid: uuid,
      attributes: payload
    });
  };

  getDuoDevicesByUserName = async () => {
    return await this.get<IGetAllDevicesByUserName>(
      '/integrations/duo/user/devices'
    );
  };
  createDuoPhone = async (request: ICreatePhoneRequest) => {
    return await this.post<ICreateDuoPhoneResponse>('/integrations/duo/phones', request);
  };
  deleteDuoPhone = async (payload: IDeletePhoneRequest) => {
    return await this.delete<IApiResponse>('/integrations/duo/phones', payload);
  };
  associateDuoToken = async (payload: IAssociateDuoTokenRequest) => {
    return await this.post('/integrations/duo/tokens/associate', payload);
  };
  disassociateDuoToken = async (payload: IDissociateDuoTokenRequest) => {
    return await this.post('/integrations/duo/tokens/disassociate', payload);
  };
  getActivationCode = async (duoPhoneId: string) => {
    return await this.get<IDuoPhoneActivation>(
      '/integrations/duo/phones/activation',
      { phoneId: duoPhoneId }
    );
  };
  resetSMS = async (setupPhoneNumber='') => {
    return await this.post(
      '/integrations/duo/user/reset_sms',
      setupPhoneNumber ? { setupPhoneNumber } : {}
    );
  };

  //INTEGRATIONS NAMESPACE - END

  //PORTAL NAMESPACE -  START
  getPortalSettings = async () => {
    return await this.get<IPortalSettings>('/portal/settings');
  };

  updatePortalSettings = async (payload: IPortalSettings) => {
    return await this.put('/portal/settings', {
      attributes: payload
    });
  };

  getPortalSettingsByNames = async (propertyNames: string[]) => {
    return await this.get<IPortalConfig>(
      '/portal/settings/by_names', { propertyNames }
    );
  };

  getAllSupportTopics = async () => {
    return await this.get<ISupportTopic[]>('portal/support/topics/all');
  };
  createSupportTopic = async (payload: ICreateSupportTopic) => {
    return await this.post('portal/support/topics', {
      supportTopic: payload
    });
  };

  updateSupportTopic = async (uuid: string, payload: ICreateSupportTopic) => {
    return await this.put('portal/support/topics', {
      uuid: uuid,
      attributes: payload
    });
  };

  contactSupport = async (payload: IContactSupport) => {
    return await this.post('portal/support/contact', {
      ...payload
    });
  };

  initializePortal = async () => {
    return await this.post('portal/init');
  };

  //PORTAL NAMESPACE -  END

  //Authentication NAMESPACE -  START

  getAllApprovedDomains = async () => {
    return await this.get<IApprovedDomain[]>(
      'authentication/approved_domains/all'
    );
  };
  createApprovedDomain = async (payload: ICreateApprovedDomain) => {
    return await this.post('/authentication/approved_domains', {
      approvedDomain: payload
    });
  };

  updateApprovedDomain = async (
    uuid: string,
    payload: IUpdateApprovedDomain
  ) => {
    return await this.put('/authentication/approved_domains', {
      uuid: uuid,
      attributes: payload
    });
  };

  //Authentication NAMESPACE -  END
}
