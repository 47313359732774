import { FC, useEffect, useState } from 'react';

import { MainServiceApi } from '../../../../lib/api/mainServiceApi';

import { IPortalSettings } from '../../../../interfaces/portal/settings';
import { FlashbarProps } from '@awsui/components-react';

import View from './view';

const PortalSecretsForm: FC = () => {
  const [formValues, setFormValues] = useState<IPortalSettings>({
    duoIntegration: {
      integrationKey: '',
      secretKey: '',
      directoryKey: '',
      apiEndpoint: '',
    },
    portalConfiguration: {
      disableUserDueToInactivityThresholdInDays: 0,
      warnUserDueToInactivityThresholdInDays: 0,
      userPasswordResetWaitTimeInHours: 0,
      enableDisableUserDueToInactivity: false,
      enableWarnUserDueToInactivity: false,
      enableUserPasswordResetWaitTime: false,
      initialized: true,
      workDocsEnabled: false,
      workMailEnabled: false,
      workMailDomain: '',
      workMailOrgId: '',
      duoEnabled: false,
      enableAutoProvision: false,
      smsVerifyExpireTimeInSeconds: 0,
      smsVerifyMaxResends: 0,
      smsVerifyMaxFailures: 0
    }
  });

  const [isLoading, setIsLoading] = useState(true);

  const [viewDuoSecrets, setViewDuoSecrets] = useState(false);

  const [settingsSectionExpanded, setSettingsSectionExpanded] = useState(true);
  const [secretsSectionExpanded, setSecretsSectionExpanded] = useState(false);

  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useEffect(() => {
    const api = new MainServiceApi();
    api
      .getPortalSettings()
      .then((data) => {
        setFormValues(data);
        setIsLoading(false);
      })
      .catch(() => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to get Portal Secrets!</>
          }
        ]);

        return {};
      });
  }, []);

  const handleSubmittedForm = async (data: IPortalSettings) => {
    setFormValues(data);
    setFlashBarItems([
      {
        type: 'success',
        loading: true,
        content: 'Processing...'
      }
    ]);

    const api = new MainServiceApi();
    api
      .updatePortalSettings(data)
      .then(() => {
        setFlashBarItems([
          {
            type: 'success',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Portal Settings and Secrets have been updated!</>
          }
        ]);
      })
      .catch((err) => {
        setFlashBarItems([
          {
            type: 'error',
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => setFlashBarItems([]),
            content: <>Unable to update Portal Settings and Secrets!</>
          }
        ]);
      });
  };

  return (
    <View
      formValues={formValues}
      handleSubmittedForm={handleSubmittedForm}
      flashBarItems={flashBarItems}
      viewDuoSecrets={viewDuoSecrets}
      setViewDuoSecrets={setViewDuoSecrets}
      settingsSectionExpanded={settingsSectionExpanded}
      setSettingsSectionExpanded={setSettingsSectionExpanded}
      secretsSectionExpanded={secretsSectionExpanded}
      setSecretsSectionExpanded={setSecretsSectionExpanded}
      isLoading={isLoading}
    />
  );
};
export default PortalSecretsForm;
