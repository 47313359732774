import { Auth } from 'aws-amplify';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { IApiResponse } from './interfaces';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;
  protected readonly headers: { [key: string]: string };

  public constructor(baseURL: string) {
    this.headers = {
      'Content-Type': 'application/json'
    };

    this.instance = axios.create({
      baseURL
    });

    this._initializeResponseInterceptor();
  }

  protected async get<R = AxiosResponse>(path: string, params = {}) {
    return (await this.instance.get(path, {
      params: params,
      headers: await this.generateHeaders()
    })) as R;
  }

  protected async post<R = IApiResponse>(path: string, body = {}) {
    return (await this.instance.post(path, body, {
      headers: await this.generateHeaders()
    })) as R;
  }

  protected async delete<R = IApiResponse>(path: string, data: {}) {
    return (await this.instance.delete(path, {
      data: data,
      headers: await this.generateHeaders()
    })) as R;
  }

  protected async put<R = IApiResponse>(path: string, body: {}) {
    return (await this.instance.put(path, body, {
      headers: await this.generateHeaders()
    })) as R;
  }

  protected generateHeaders = async () => {
    return {
      ...this.headers,
      ...{
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };
  };

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _handleResponse = (resp: AxiosResponse) => {
    if (resp.config.method === 'get') {
      return resp.data.data;
    } else {
      return resp.data;
    }
  };

  protected _handleError = async (err: AxiosError) => {
    throw err.response?.data.message
      ? err.response.data.message
      : err.message;
  };
}
