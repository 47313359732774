import { Auth } from 'aws-amplify';
import { FC, useState } from 'react';

import {
  AppLayout,
  Button,
  Container,
  Flashbar,
  FlashbarProps,
  Form,
  FormField,
  Input,
  SpaceBetween
} from '@awsui/components-react';

import Header from '../common/Header';
import Footer from '../common/Footer';
import SideNav from '../common/SideNav';
import ToolBox from '../common/ToolBox';

enum authStep {
  emailStep = 'emailStep',
  answerStep = 'answerStep'
}
interface IAuthentication {
  logIn: () => void;
}
const Authentication: FC<IAuthentication> = ({ logIn }) => {
  const [username] = useState(crypto.randomUUID())
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(authStep.emailStep);
  const [answer, setAnswer] = useState('');
  const [thisUser, setThisUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [flashBarItems, setFlashBarItems] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  // This handles sign up with email user entered and randomly generated password
  const signUp = async () => {
    const params = {
      username: username,
      password: getRandomString(30),
      attributes: {
        email: email
      }
    };
    try {
      setLoading(true);
      await Auth.signUp(params);
      // Must sign in with username since email is not verified yet
      signInWithUsername();
    } catch (e: any) {
        setLoading(false);
        setFlashBarItems(() => [
          {
            type: 'error',
            onButtonClick: () => setFlashBarItems([]),
            content: <>Unable to sign up, contact your admin</>
          }
        ]);
    }
  };
  
  // This handles sign in with email
  const signInWithEmail = async () => {
    try {
      setLoading(true);
      setThisUser(await Auth.signIn(email));
      setStep(authStep.answerStep);
      setFlashBarItems(() => [
        {
          type: 'success',
          content: <>Check your email for verification code.</>
        }
      ]);
      setLoading(false); // stop the loading flashbar after the email form
    } catch (e: any) {
      if (e.code === 'UserLambdaValidationException') {
        signUp();
      } else {
      setLoading(false);
      setFlashBarItems(() => [
        {
          type: 'error',
          onButtonClick: () => setFlashBarItems([]),
          content: <>Unable to sign in, contact your admin</>
        }
      ]);
    }
  }
};

  // This handles sign in with username
  const signInWithUsername = async () => {
    try {
      setLoading(true);
      setThisUser(await Auth.signIn(username));
      setStep(authStep.answerStep);
      setFlashBarItems(() => [
        {
          type: 'success',
          content: <>Check your email for verification code.</>
        }
      ]);
      setLoading(false); // stop the loading flashbar after the email form
    } catch (e: any) {
      // Error thrown by custom auth
      if (e.code === 'UserLambdaValidationException') {
        signUp();
      } else {
      setLoading(false);
      setFlashBarItems(() => [
        {
          type: 'error',
          onButtonClick: () => setFlashBarItems([]),
          content: <>Unable to sign in, contact your admin</>
        }
      ]);
    }
  }
};

  // This generates random password for sign up
  const getRandomString = (bytes: number) => {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).join('');
  };

  // This handles email input so we can verify if the user enters the correct email to sign in
  const handleEmailInput = async (event: any) => {
    event.preventDefault();
    setFlashBarItems(() => []);
    // Attempt sign in but if the doesnt exist, sign up instead
    // Sign in with email since username is randomized
    signInWithEmail();    
  };

  const isAuthenticated = async () => {
    try {
      await Auth.currentSession();
      return true;
    } catch {
      return false;
    }
  };

  const answerCustomChallenge = async (event: any) => {
    event.preventDefault();
    setFlashBarItems(() => []);
    setLoading(true);

    try {
      await Auth.sendCustomChallengeAnswer(thisUser, answer);

      if (await isAuthenticated()) {
        logIn();
      } else {
        setLoading(false);
        setFlashBarItems(() => [
          {
            type: 'error',
            onButtonClick: () => setFlashBarItems([]),
            content: <>Incorrect verification code</>
          }
        ]);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.code === 'NotAuthorizedException') {
        setFlashBarItems(() => [
          {
            type: 'info',
            onButtonClick: () => setFlashBarItems([]),
            content: <>Invalid Session!</>,
            action: (
              <Button
                onClick={(event) => {
                  handleEmailInput(event);
                  setAnswer('');
                }}
              >
                Resend Email?
              </Button>
            )
          }
        ]);
      } else {
        setFlashBarItems(() => [
          {
            type: 'error',
            onButtonClick: () => setFlashBarItems([]),
            content: <>Something went wrong. Contact your admin!</>
          }
        ]);
      }
    }
  };

  const loadingFlash = (
    <Flashbar
      items={[
        {
          type: 'success',
          loading: true,
          content: 'Processing...'
        }
      ]}
    />
  );

  const emailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const emailForm = (
    <SpaceBetween direction="vertical" size="xs">
      <Container header={<h3>Authentication</h3>}>
        <Flashbar items={flashBarItems} />
        {loading ? loadingFlash : <></>}
        <Form
          id="email-form"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                id="email-form-submit"
                variant="primary"
                onClick={handleEmailInput}
                disabled={!email.match(emailformat)}
              >
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          <FormField
            label="Corporate Email"
            description="Enter your corporate email"
            errorText={
              email.match(emailformat)
                ? ''
                : email === ''
                ? ''
                : 'email must be a valid email'
            }
          >
            <Input
              value={email}
              name="email"
              onChange={({ detail }) => setEmail(detail.value.toLowerCase())}
            />
          </FormField>
        </Form>
      </Container>
    </SpaceBetween>
  );
  const answerForm = (
    <SpaceBetween direction="vertical" size="xs">
      <Container header={<h3>Authentication</h3>}>
        {loading ? loadingFlash : <></>}
        <Form
          id="answer-form"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                id="answer-form-submit"
                variant="primary"
                onClick={answerCustomChallenge}
                disabled={answer.length < 6}
              >
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          <Flashbar items={flashBarItems} />
          <FormField
            label="Verification Code"
            description="Enter the verification code that was sent to your email."
            errorText={
              answer.length === 6 || answer.length === 0
                ? ''
                : 'Must be a valid secret!'
            }
          >
            <Input
              id="answer-field"
              name="answer"
              value={answer}
              onChange={({ detail }) => setAnswer(detail.value)}
            />
          </FormField>
        </Form>
      </Container>
    </SpaceBetween>
  );
  let content = <div>Something went wrong</div>;
  // use switch to decide which step to follow depending on user's input
  switch (step) {
    case authStep.emailStep:
      content = emailForm;
      break;
    case authStep.answerStep:
      content = answerForm;
      break;
  }
  return (
    <>
      <Header />
      <AppLayout
        footerSelector=".footer"
        headerSelector=".header"
        navigation={<SideNav />}
        content={content}
        tools={<ToolBox />}
        toolsHide={true}
        navigationHide={true}
      ></AppLayout>
      <Footer />
    </>
  );
};
export default Authentication;
